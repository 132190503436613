import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    ranchoCordovaImage: file(relativePath: { eq: "rancho-cordova.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWithCarImage: file(
      relativePath: { eq: "locksmith-with-car.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInVanImage: file(relativePath: { eq: "leo-in-van.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function RioLindaPage({ data }) {
  return (
    <MainLayout
      title="Mobile Locksmith Rio Linda, CA | 916-995-0135 | Open 24/7"
      description="We offer full emergency, commercial, residential & automotive locksmith services in Rio Linda, CA. Lockouts, lock installation, rekeying, & security solutions."
      offset
    >
      <ContactHeading
        background={data.ranchoCordovaImage.childImageSharp.fluid}
      >
        <h1 className="text-center">Locksmith Rio Linda</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Locksmith in Rio Linda, CA
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section" className="pb-0 pb-sm-5">
        <h2 className="text-center text-primary">
          Full Service Rio Linda Locksmith
        </h2>
        <p className="indent">
          Swift Locksmith is a licensed locksmith company offering the best
          residential, commercial, and automotive locksmith services for the Rio
          Linda community and nearby areas. We truly look after the security and
          safety of our clients and offer 24/7 customer care to help you with
          any locksmith need that you might have, at any given time. All of our
          locksmith technicians are licensed professionals who have been working
          in the locksmith industry for many years and have gained understanding
          and experience about everything associated with locksmith services,
          which means you are able to be confident in trusting us with your job.
        </p>
        <p className="indent">
          Our team is friendly, professional and knowledgeable so we can provide
          you with different options based on your individual needs for
          security. We operate in an organized, productive fashion to ensure
          that your locksmith problems are resolved easily and carefully without
          harming any of your property or things. We're dedicated to proving you
          with outstanding customer service, competitive pricing and quality
          materials.
        </p>
        <Row>
          <Col className="col-sm-pad">
            <Img
              fluid={data.locksmithWithCarImage.childImageSharp.fluid}
              alt="locksmith-with-car"
            />
          </Col>
          <Col className="d-none d-md-block">
            <Img
              fluid={data.leoInVanImage.childImageSharp.fluid}
              alt="leo-in-van"
            />
          </Col>
        </Row>
      </Container>
      <section className="pt-0 pt-md-5">
        <div className="background-content bg-dark text-white">
          <Container>
            <h2>Local Locksmith Services</h2>
            <h3 className="mb-2 text-primary">
              Residential Locksmith Services
            </h3>
            <Row className="mb-3">
              <Col xs={12} md={6} lg={5} xl={4}>
                <ul className="mb-0">
                  <li className="lead">24/7 Emergency Lockout</li>
                  <li className="lead">Door Knob Lock Installation</li>
                  <li className="lead">Whole House Rekey</li>
                  <li className="lead">Lock Repair & Maintenance</li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={7} xl={8}>
                <ul className="mb-0">
                  <li className="lead">High Security Locks</li>
                  <li className="lead">Duplicate House Keys</li>
                  <li className="lead">On-Site Key Extraction</li>
                  <li className="lead">Master Key Systems</li>
                </ul>
              </Col>
            </Row>
            <h3 className="mb-2 text-primary">Automotive Locksmith Services</h3>
            <Row className="mb-3">
              <Col xs={12} md={6} lg={5} xl={4}>
                <ul className="mb-0">
                  <li className="lead">24/7 Emergency Lockout</li>
                  <li className="lead">Broken Key Extraction</li>
                  <li className="lead">Ignition Rekey</li>
                  <li className="lead">Laser Cut Keys</li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={7} xl={8}>
                <ul className="mb-0">
                  <li className="lead">Chip Keys Cut Services</li>
                  <li className="lead">Remote Programming</li>
                  <li className="lead">Lost Car Key Replacement</li>
                  <li className="lead">Replace Keyless Entry Remotes</li>
                </ul>
              </Col>
            </Row>
            <h3 className="mb-2 text-primary">Commercial Locksmith Services</h3>
            <Row className="mb-3">
              <Col xs={12} md={6} lg={5} xl={4}>
                <ul className="mb-0">
                  <li className="lead">24/7 Emergency Lockout</li>
                  <li className="lead">Business Rekey</li>
                  <li className="lead">Locks & Exit Device Repairs</li>
                  <li className="lead">Master Key System Installation</li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={7} xl={8}>
                <ul className="mb-0">
                  <li className="lead">Keyless Access System</li>
                  <li className="lead">
                    Aluminum Door Lock Alignment & Installation
                  </li>
                  <li className="lead">On-site Key Duplication</li>
                  <li className="lead">High Security & Standard Deadbolts</li>
                </ul>
              </Col>
            </Row>
            <h3 className="mb-2 text-primary">Emergency Locksmith Services</h3>
            <Row>
              <Col xs={12} md={6} lg={5} xl={4}>
                <ul className="mb-0">
                  <li className="lead">Emergency Rekey Services</li>
                  <li className="lead">Car,House, Business Lockouts</li>
                  <li className="lead">Emergency Lock Repair Services</li>
                  <li className="lead">Bedroom,Storage, Garage Lockouts</li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={7} xl={8}>
                <ul className="mb-0">
                  <li className="lead">Mail Box Lockouts</li>
                  <li className="lead">Replacement of Lost Car Keys</li>
                  <li className="lead">Broken Key Extraction</li>
                  <li className="lead">Duplicate Keys</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section" className="mb-5">
        <h2>Choosing Swift Locksmith in Rio Linda, CA</h2>
        <p className="indent mb-0">
          Your security and safety becomes our priority when you call Swift
          Locksmith. Whether you want a second business key, locked out of your
          vehicle, or lost the house keys, our expert technicians will come to
          you instantly to aid you with any kind of locksmith issue in a timely
          manner. You will never have to question the quality of service you're
          going to get since we ensure each one of our jobs as well as provide
          all costs for you before we start the job.
        </p>
      </Container>
    </MainLayout>
  )
}

export default RioLindaPage
